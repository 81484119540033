import React from "react";

import AccountBanner from "../../components/AccountBanner"
import { Form, FormGroup, Label, Input, Popover, PopoverBody, CustomInput } from "reactstrap";
import Select from 'react-select'
import ResetPasswordForm from "views/Admin/ResetPasswordForm";
import Modal from "components/Modal";
import ReactModal from 'react-modal';
import ActionButton from "components/ActionButton";

import { getRequest, postRequest } from "sharedUtils/httpUtils";

import { debounce } from "throttle-debounce";
import toast, { Toaster } from 'react-hot-toast';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
const { DateTime } = require("luxon");

class Users extends React.Component {
    state = {
        selected: [],
        subaffiliates: [],
        options: [],
        rpManagerOptions: [],
        value: [],
        isLoading: false,
        isLoadingReferral: false,
        isLoadingRPManagerUsers: false,
        isCheckingUserName: false,
        uploadingImage: false,
        profilePicUpdated: false,
        profilePic: "",
        profilePicUrl: "",
        selectedProStatusId: "",
        showChangePasswordForm: false,
        show: false,
        paymentEmail: "",
        amazonSubaffiliate: "",
        savingAmazonSub: false,
        subaffiliatesFetched: false,
        formData: {
            displayName: "",
            newUserName: "",
            profileUrl: "",
            bio: "",
            tagLine: "",
            proStatusId: "",
            isAdmin: "",
            id: "",
            onboardingComplete: "",
            referrerUserId: null,
        },
        newUserNameAvailable: null,
        validUserName: true,
        referralEntity: [],
        rpManagerEntity: [],
    };

    selectedFile = "";

    readOnlyFields = ["profileUrl", "displayName", "tagLine","email", "paymentEmail"];

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/Users', text: 'Users' }
    ]

    proStatusIds = [
        { value: 0, label: 'General User' },
        { value: 1, label: 'Creator' },
        { value: 2, label: 'Merchant Partner' }];

    setSelectedFile = async (e) => {
        this.setState({ uploadingImage: true, profilePic: e.target.files[0] });

        const formData = new FormData();
        formData.append("File", e.target.files[0]);

        let uploadProfilePicUrl = '/api/Account/UploadProfilePic';

        let user = await postRequest(uploadProfilePicUrl, formData, null, false);
        
        this.setState({ profilePicUrl: user.imageUrl, profilePic: "" });
        this.setState({ profilePicUpdated: true, uploadingImage: false })

        setTimeout(() => this.setState({ profilePicUpdated: false }), 3000);
    }

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.selected !== this.state.selected) {
            this.setState({ subaffiliates: [], amazonSubaffiliate: "", subaffiliatesFetched: false });
        }
    }

    confirmEmail = async (e) => {
        e.preventDefault();

        let url = `/api/Account/ConfirmEmail?userIdToUpdate=${this.state.formData.id}`;

        let confirmed = await getRequest(url);
    }

    updateEmail = async (e) => {
        e.preventDefault();

        let url = `/api/Account/UpdateUserEmail?userIdToUpdate=${this.state.formData.id}&email=${this.state.formData["email"]}`;
        let updateEmail = await getRequest(url);
    }

    onAmazonSubaffiliateInputChange = (e) => {
        e.preventDefault();
        this.setState({ newUserNameAvailable: null })
        let amazonSubaffiliate = e.target.value;
        this.setState({ amazonSubaffiliate });
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({ show: !this.state.show });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    onNewUserNameInputChange = async (e) => {
        e.preventDefault();
        this.setState({ newUserNameAvailable: null })
        let formKey = e.target.name;
        let newData = e.target.value;
        let formData = this.state.formData;

        formData[formKey] = newData;

        this.setState({ formData });

        let userNameRegex = /^[0-9a-zA-Z\.\-_]{1,20}$/
        let validUserName = userNameRegex.test(formData.newUserName);

        if (validUserName) {
            this.debounceNewUserNameInputChange();
            this.setState({ validUserName: true })
        } else if (formData.newUserName.length > 0) {
            this.setState({ validUserName: false })
        } else {
            this.setState({ validUserName: true })
        }
    }

    debounceNewUserNameInputChange = debounce(300, async () => {
        await this.checkUserNameExists();
    });

    onInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;
        let formData = this.state.formData;

        if (formKey === 'isAdmin') {
            newData = !this.state.formData.isAdmin;           
        }

        if (formKey === 'onboardingComplete') {
            newData = !this.state.formData.onboardingComplete;
        }

        if (formKey === 'bio') {
            e.target.style.height = 'inherit';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }

        formData[formKey] = newData;

        this.setState({ formData });
    };

    onSelectedProStatusId = (e) => {
        let formData = this.state.formData;
        formData.proStatusId = e.value;
        this.setState({ selectedProStatusId: e }) 
    }

    onClickImageUpload = (event) => {
        event.preventDefault();
        document.getElementById("profiePicInput").click();
    };

    fetchUsers = async (query) => {
        this.setState({ isLoading: true });
        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;

        let users = await getRequest(url);
        this.setState({ options: users, isLoading: false });
    }

    fetchReferralUsers = async (query) => {
        this.setState({ isLoadingReferral: true });
        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;

        let users = await getRequest(url);
        this.setState({ referralOptions: users, isLoadingReferral: false });
    }

    fetchRPManagerUsers = async (query) => {
        this.setState({ isLoadingRPManagerUsers: true });
        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;

        let users = await getRequest(url);
        this.setState({ rpManagerOptions: users, isLoadingRPManagerUsers: false });
    }

    fetchUserByReferralUserId = async (referralUserId) => {
        this.setState({ isLoading: true });
        let url = `/api/Account/GetUserById?userId=${referralUserId}`;

        let referralEntity = [await getRequest(url)];
        return referralEntity;
    }

    fetchUserByRPManagerUserId = async (rpManagerUserId) => {
        this.setState({ isLoading: true });
        let url = `/api/Account/GetUserById?userId=${rpManagerUserId}`;

        let rpManagerEntity = [await getRequest(url)];
        return rpManagerEntity;
    }

    checkUserNameExists = async () => {
        this.setState({ isCheckingUserName: true });

        let url = `/api/Account/GetUserSuggestionsByUsername?username=${this.state.formData.newUserName}`;

        let users = await getRequest(url);
        let newUserNameAvailable = true;
        let usersCheck = users.filter(user => user.userName.toLowerCase() == this.state.formData.newUserName.toLowerCase())

        if (usersCheck != null && usersCheck.length > 0) {
            newUserNameAvailable = false;
        }
        this.setState({ newUserNameAvailable, isCheckingUserName: false });
    }

    toggleResetPasswordModal = () => {
        this.setState({ showChangePasswordForm: !this.state.showChangePasswordForm });
    }

    resetPasswordError = () => {
        toast.error(
            <div className="p-2">
                <h4 className="text-center">Password reset error. Try again.</h4>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
    }

    resetPasswordSuccess = () => {
        toast.success(
            <div className="p-2">
                <h4 className="text-center">Password successfully updated</h4>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
    }

    displayChangePasswordForm = () => {
        return (
            <Modal isOpen={this.state.showChangePasswordForm} toggleModal={this.toggleResetPasswordModal}>
                <ResetPasswordForm user={this.state.selected[0]} toggleResetPasswordModal={this.toggleResetPasswordModal} resetPasswordError={this.resetPasswordError} resetPasswordSuccess={this.resetPasswordSuccess} />
            </Modal>
        )
        
    }

    displayResetPasswordButton = () => {
        let buttonText = "Reset Password";
        let buttonClasses = "btn btn-secondary mt-3 mx-1";
        if (this.state.selected?.length > 0) {
            return (
                <button className={ buttonClasses} onClick={(e) => {
                    e.preventDefault();
                    this.setState({ showChangePasswordForm: !this.state.showChangePasswordForm });

                }}>{buttonText}</button>
            )
        }
        else {
            return (
                <button className={buttonClasses} disabled>
                    {buttonText}

                </button>
                )
        }
    }

    handleImpersonateClick = () => {
        // Construct the impersonation URL with the userId as a query parameter
        const impersonateUrl = `/api/Account/ImpersonateUser?creatorUserId=${this.state.selected[0].id}`;

        // Open a new tab for the impersonation
        window.open(impersonateUrl, '_blank');
        window.close(); // This will close the tab if opened by script

    };

    displayImpersonateButton = () => {
        let buttonClasses = "btn btn-secondary mt-3 mx-1";

        if (this.state.selected.length > 0) {
            return (
                <button className={buttonClasses} onClick={this.showModal}>Impersonate User</button>
            );
        }

    };

    mapInputFields = (fields) => {
        return fields.map((field, i) => {
            if (this.readOnlyFields.includes(field.name) || this.state.uploadingImage) {
                if (field.name === "referralUserId") {
                    return <></>;
                }
                if (field.name === "email") {
                    return (
                        <FormGroup key={i} className="input-group">

                            <div className="input-group-prepend">
                                <span className="input-group-text">{field.title}</span>
                            </div>
                            
                            <Input
                                className="form-control"
                                name={field.name}
                                onChange={(e) => this.onInputChange(e)}
                                value={this.state.formData[field.name]}
                            />

                            <div className="input-group-append">
                                <button className="btn btn-primary" onClick={this.confirmEmail}>
                                    Confirm Email
                                </button>
                            </div>
                            
                        </FormGroup>
                        )
                }
                else if (field.name === "paymentEmail") {
                    return (
                        <FormGroup key={i} className="input-group">

                            <div className="input-group-prepend">
                                <span className="input-group-text">{field.title}</span>
                            </div>

                            <Input
                                className="form-control"
                                name={field.name}
                                value={this.state.paymentEmail}
                                readOnly
                            />
                        </FormGroup>
                    )
                }
                else {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <Input
                                className="form-control"
                                name={field.name}
                                onChange={(e) => this.onInputChange(e)}
                                value={this.state.formData[field.name]}
                                readOnly="readonly"
                            />
                        </FormGroup>
                    );
                }
                
            }
            else {
                if (field.name === "bio") {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <textarea
                                className="form-control"
                                name={field.name}
                                onChange={(e) => this.onInputChange(e)}
                                value={this.state.formData[field.name]}
                                readOnly="readOnly"
                                style={{ overflow: "hidden" }}
                            />
                        </FormGroup>);
                }
                else if (field.name === "isAdmin") {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <CustomInput
                                id="isAdmin"
                                type="switch"
                                name={field.name}
                                onChange={(e) => this.onInputChange(e)}
                                checked={this.state.formData[field.name]} />
                        </FormGroup>);
                }
                else if (field.name === "onboardingComplete") {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <CustomInput
                                id="onboardingComplete"
                                type="switch"
                                name={field.name}
                                onChange={(e) => this.onInputChange(e)}
                                checked={this.state.formData[field.name]} />
                        </FormGroup>);
                }
                else if (field.name === "proStatusId") {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <Select
                                isClearable={false}
                                isSearchable={false}
                                placeholder="Select user type..."
                                blurInputOnSelect={true}
                                captureMenuScroll={true}
                                onChange={(i) => { this.onSelectedProStatusId(i); }}
                                value={this.state.selectedProStatusId}
                                options={this.proStatusIds} />
                        </FormGroup>);
                }
                else if (field.name === "newUserName") {
                    const newUserNameAvailable = this.state.newUserNameAvailable == null ? "" : this.state.newUserNameAvailable ? "Available" : "Not Available";
                    return (
                        <FormGroup key={i} className="form-group">
                            <p style={{ color: "red", display: this.state.validUserName ? "none" : "" }}>A username can only contain letters and numbers.</p>
                            <Label className="control-label">{field.title}</Label>
                            <div className="input-group">
                                <Input
                                    className="form-control"
                                    name={field.name}
                                    onChange={(e) => this.onNewUserNameInputChange(e)}
                                    value={this.state.formData[field.name]}
                                    maxLength={20}
                                />
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ color: this.state.newUserNameAvailable ? "green" : "red" }}>
                                        <span className="spinner-border spinner-border-sm mr-2 spinner-display-none text-dark" style={{ display: !this.state.isCheckingUserName || this.state.formData.newUserName.length == 0 ? "none" : "" }}></span>
                                        {newUserNameAvailable}
                                    </span>
                                </div>
                            </div>
                        </FormGroup>);
                }
                if (field.prepend) {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">{field.prepend}</span>
                                </div>
                                <Input
                                    className="form-control"
                                    name={field.name}
                                    onChange={(e) => this.onInputChange(e)}
                                    value={this.state.formData[field.name]}
                                    maxLength={field.name === "displayName" ? 20 : undefined}
                                />
                            </div>
                        </FormGroup>
                    );
                }
                return (
                    <FormGroup key={i} className="form-group">
                        <Label className="control-label">{field.title}</Label>
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => this.onInputChange(e)}
                            value={this.state.formData[field.name]}
                            maxLength={field.name === "displayName" ? 20 : undefined}
                        />
                    </FormGroup>
                );
            }
        });
    }

    mapInputFieldsPublic = () => {
        let fields = [
            { name: "displayName", title: "Name" },
            { name: "newUserName", title: "New Username" },
            { name: "tagLine", title: "Tag Line" },
            { name: "bio", title: "Bio" },
            { name: "isAdmin", title: "Administrator" },
            { name: "onboardingComplete", title: "Onboarding Complete"},
            { name: "proStatusId", title: "User Type" },
            { name: "email", title: "Email" },
            { name: "paymentEmail", title: "Payment Email" },
        ];
        return this.mapInputFields(fields);
    };

    convertToUserModel = () => {
        const userName = this.state.formData.newUserName.length > 0 && this.state.validUserName && this.state.newUserNameAvailable ?
            this.state.formData.newUserName : this.state.formData.userName;
        return {
            name: this.state.formData.displayName,
            bio: this.state.formData.bio,
            tagLine: this.state.formData.tagLine,
            website: this.state.formData.website,
            googleUsername: this.state.formData.googleUsername,
            facebookUsername: this.state.formData.facebookUsername,
            instagramUsername: this.state.formData.instagramUsername,
            imageUrl: this.state.formData.imageUrl,
            userName: userName,
            email: this.state.formData.email,
            phoneNumber: this.state.formData.phoneNumber,
            proStatusId: this.state.formData.proStatusId,

            address: this.state.formData.address,
            city: this.state.formData.city,
            state: this.state.formData.state,
            postalCode: this.state.formData.postalCode,
            country: this.state.formData.country,

            onboardingComplete: this.state.formData.onboardingComplete,
            profileBannerUrl: this.state.formData.profileBannerUrl,

            isAdmin: this.state.formData.isAdmin,
            id: this.state.formData.id,

            referralUserId: this.state.formData.referralUserId,
            rpManagerUserId: this.state.formData.rpManagerUserId
        }
    }

    onSubmit = async (event) => {
        event.preventDefault();
        this.setState({ savingProfile: true });

        let userUrl = '/api/Account/AdminUpdateUser';
        await postRequest(userUrl, this.convertToUserModel());

        this.setState({ showSavedProfile: true });

        setTimeout(() => this.setState({ savingProfile: false, showSavedProfile: false }), 2000);
    };

    renderSubmit = () => {
        let submitDisabled = true;

        for (let key in this.state.formData) {
            if (this.state.formData[key] !== null && this.state.formData[key] !== "") {
                submitDisabled = false;
                break;
            }
        }

        if (this.state.profilePic !== "") {
            submitDisabled = false;
        }

        if (this.state.formData.newUserName !== "" && (!this.state.validUserName || !this.state.newUserNameAvailable)) {
            submitDisabled = true;
        }

        if (submitDisabled) {
            return (
                <button type="submit" className="btn btn-secondary mt-3" id="submitProfileChanges" disabled onClick={(e) => { this.onSubmit(e); }}>
                    {this.state.savingProfile ?
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        : ""} Submit
                </button>
            );
        }

        return (
            <button type="submit" id="submitProfileChanges" className="btn btn-secondary mt-3" onClick={(e) => { this.onSubmit(e); }}>
                {this.state.savingProfile ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    : ""} Submit
            </button>
        );
    }

    setSelectedReferral = (selected) => {
        let formData = { ...this.state.formData };
        formData.referralUserId = selected.length > 0 ? selected[0].id : null;

        this.setState({ referralEntity: selected, formData });
    }

    setSelectedRPManager = (selected) => {
        let formData = { ...this.state.formData };
        formData.rpManagerUserId = selected.length > 0 ? selected[0].id : null;

        this.setState({ rpManagerEntity: selected, formData });
    }

    getPaymentEmail = async (userId) => {
        let paymentEmail = await getRequest("api/Account/GetPaymentEmail?userId=" + userId);
        this.setState({ paymentEmail: !!(paymentEmail?.email) ? paymentEmail.email : "NO PAYMENT EMAIL ON FILE" });
    }

    setSelected = async (selected) => {
        if (selected.length > 0 && !!(selected[0].id) && selected[0].id > 0) {
            await this.getPaymentEmail(selected[0].id);
        }

        let formData = {
            displayName: "",
            newUserName: "",
            bio: "",
            tagLine: "",
            imageUrl: "",
            userName: "",
            email: "",
            proStatusId: "",
            onboardingComplete: "",
            isAdmin: "",
            referralUserId: null,
            rpManagerUserId: null,
        }

        let referralEntity = [];
        let rpManagerEntity = [];

        let selectedProStatusId = this.proStatusIds[0];

        if (selected.length > 0) {

            formData = {
                displayName: selected[0].name,
                newUserName: "",
                bio: selected[0].bio,
                tagLine: selected[0].tagLine,
                website: selected[0].website,
                googleUsername: selected[0].googleUsername,
                facebookUsername: selected[0].facebookUsername,
                instagramUsername: selected[0].instagramUsername,
                imageUrl: selected[0].imageUrl,
                userName: selected[0].userName,
                email: selected[0].email,
                phoneNumber: selected[0].phoneNumber,
                proStatusId: selected[0].proStatusId,

                address: selected[0].address,
                city: selected[0].city,
                state: selected[0].state,
                postalCode: selected[0].postalCode,
                country: selected[0].country,

                onboardingComplete: selected[0].onboardingComplete,
                profileBannerUrl: selected[0].profileBannerUrl,

                quickStart: selected[0].quickStart,

                isAdmin: selected[0].isAdmin,
                id: selected[0].id,
            }          

            selectedProStatusId = this.proStatusIds[selected[0].proStatusId];
            this.getSubaffiliates(selected[0].id);

            if (!!(selected[0].referralUserId)) {
                formData.referralUserId = selected[0].referralUserId;
                referralEntity = await this.fetchUserByReferralUserId(selected[0].referralUserId);
            }
            if (!!(selected[0].rpManagerUserId)) {
                formData.rpManagerUserId = selected[0].rPManagerUserId;
                rpManagerEntity = await this.fetchUserByRPManagerUserId(selected[0].rpManagerUserId);
            }
        }


        this.setState({ selected, formData, profilePicUrl: formData.imageUrl, selectedProStatusId, referralEntity, rpManagerEntity, isLoading: false });
    }

    getSubaffiliates = async (userId) => {
        if (userId > 0) {
            const url = `/api/Account/GetSubaffiliatesByUserId?userId=${userId}`;
            let subaffiliates = await getRequest(url);
            subaffiliates.sort((a, b) => (a.affiliateName > b.affiliateName) ? 1 : -1)
            const amazonSubaffiliateObject = subaffiliates.filter(sub => sub.affiliateName === "Amazon")?.[0];
            const amazonSubaffiliate = !!(amazonSubaffiliateObject?.subaffiliateID) ? amazonSubaffiliateObject.subaffiliateID : "";
            this.setState({ subaffiliates, amazonSubaffiliate, subaffiliatesFetched: true });
        }
    }

    postAmazonSubaffiliate = async () => {

        if (this.state.amazonSubaffiliate.trim().length > 0) {
            this.setState({ savingAmazonSub: true });
            const url = `/api/Account/PostAmazonSubaffiliateByUserId?userId=${this.state.selected[0].id}&subaffiliate=${this.state.amazonSubaffiliate.trim()}`;
            const subaffiliateObject = {
                subaffiliateID: this.state.amazonSubaffiliate.trim(),
                userId: this.state.selected[0].id,
                affiliateName: "Amazon",
            };
            await postRequest(url, subaffiliateObject);
            await this.getSubaffiliates(this.state.selected[0].id);
            await this.createUserMerchantCommissionOverride();
            this.setState({ savingAmazonSub: false });  
        }
    }

    createUserMerchantCommissionOverride = async () => {
        let amazonMapmArray = await getRequest(`api/AffiliateProgramManager/GetMerchantAffiliateProgamManagersByHostname?hostname=www.amazon.com`);
        let amazonMapm = amazonMapmArray[0];
        let request = {
            merchantAffiliateProgramManagerId: amazonMapm.merchantAffiliateProgramManagerId,
            userId: this.state.formData.id,
            userShare: 0.75,
            effectiveDate: DateTime.fromISO(`${DateTime.now().toFormat('yyyy-MM-dd')}T00:00`),
            offset: DateTime.now().offset
        };

        let addUserMerchantCommissionOverrideUrl = '/api/Merchant/AddUserMerchantCommissionOverride';
        await postRequest(addUserMerchantCommissionOverrideUrl, request);
    }

    displayTableRows = () => {
        if (this.state.subaffiliates?.length > 0) {
            return this.state.subaffiliates?.map((item, i) => {
                return (
                    <tr key={i}>
                        <td>{item?.affiliateName}</td>
                        <td>{item?.subaffiliateID}</td>
                    </tr>
                )
            })
        } else {
            return (
                <tr key={0}>
                    <td></td>
                    <td></td>
                </tr>
            );
        }
        }
       
            
    render() {
        return (
            <>
                <Toaster />
                <ReactModal isOpen={this.state.show}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                border: '1px solid #ccc',
                                background: '#fff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '550px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => this.setState({ show: false })}
                >

                    <div className="d-flex flex-column justify-content-center h-100">
                        <div className="mx-0 my-0">
                            <h1 style={{ textAlign: "center", display: "block", fontSize: '1.75rem' }}>
                                Are you sure that you want to Impersonate this user?
                            </h1>
                            <p style={{ textAlign: 'center', marginTop: "35px", fontWeight: "bold" }}>
                                This will log you out as you, log you in as this user, and open the browser to that user's default primary page.
                            </p>
                            <p style={{ textAlign: 'center', marginTop: "10px", fontWeight: "bold" }}>
                                To return you will have to logout, log back in as yourself, and navigate back this page.
                            </p>
                        </div>

                        <div className="d-flex justify-content-center gap-3 mt-4">
                            <button type="button" className="btn btn-primary mr-2" onClick={this.handleImpersonateClick}>Yes</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={() => this.setState({ show: false })}>No</button>
                        </div>
                    </div>
                </ReactModal>
                {this.displayChangePasswordForm()}
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Users</h1>
                        <p className="mb-0">Edit user details</p>
                    </AccountBanner>
                </section>
                <section className="gray-bg full-height">
                    <div className="container pt-0 pb-5">
                        <div className="search-interest py-4 mx-auto">
                            <div className="search-block">
                                <div className="position-relative mx-auto">
                                    <AsyncTypeahead
                                        id="userSearch"
                                        labelKey="userName"
                                        defaultSelected={this.state.value}
                                        clearButton
                                        onSearch={this.fetchUsers}
                                        onChange={selected => this.setSelected(selected)}
                                        isLoading={this.state.isLoading}
                                        options={this.state.options}
                                        placeholder="Search for users..."/>
                                </div>
                            </div>
                        </div>
                            <div className="user-detail-form">
                                <Form>
                                    <div className="detail-block">
                                        <h5>Public Profile</h5>

                                        <div className="form-group upload-image">
                                            <div className="avatar-upload">

                                                <div className="avatar-preview">                                         
                                                    <div id="imagePreview" style={{ backgroundImage: 'url(' + this.state.profilePicUrl + ')' }}>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    {this.mapInputFieldsPublic()}
                                    <div className="search-interest py-4 mx-auto">
                                        <div className="search-block">
                                            <div className="position-relative mx-auto">
                                                <Label className="control-label">Referrer</Label>
                                                <AsyncTypeahead
                                                    id="userSearch"
                                                    labelKey="userName"
                                                    selected={this.state.referralEntity}
                                                    clearButton
                                                    onSearch={this.fetchReferralUsers}
                                                    onChange={selected => this.setSelectedReferral(selected)}
                                                    options={this.state.referralOptions}
                                                    isLoading={this.state.isLoadingReferral}
                                                    placeholder="Search for users..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search-interest py-4 mx-auto">
                                        <div className="search-block">
                                            <div className="position-relative mx-auto">
                                                <Label className="control-label">Creator Team Member Manager</Label>
                                                <AsyncTypeahead
                                                    id="userSearch"
                                                    labelKey="userName"
                                                    selected={this.state.rpManagerEntity}
                                                    clearButton
                                                    onSearch={this.fetchRPManagerUsers}
                                                    onChange={selected => this.setSelectedRPManager(selected)}
                                                    options={this.state.rpManagerOptions}
                                                    isLoading={this.state.isLoadingRPManagerUsers}
                                                    placeholder="Search for RockPorch manager..." />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.subaffiliatesFetched ?
                                            <div>
                                                <Label className="control-label" style={{ fontSize: "16px", fontWeight: "400" }}>Create Amazon Subaffiliate</Label>

                                                <FormGroup className="input-group">
                                                    <Input
                                                        className="form-control"
                                                        name="amazonSubaffiliate"
                                                        onChange={(e) => this.onAmazonSubaffiliateInputChange(e)}
                                                        value={this.state.amazonSubaffiliate}
                                                    />

                                                    <div className="input-group-append">
                                                        {
                                                            this.state.amazonSubaffiliate.length === 0 || (this.state.subaffiliates.filter(sub => sub.affiliateName === "Amazon").length > 0 && this.state.subaffiliates.filter(sub => sub.affiliateName === "Amazon")[0].subaffiliateID === this.state.amazonSubaffiliate) ?
                                                                <ActionButton className="btn btn-primary"
                                                                    isDisabled={true}
                                                                    onClick={(e) => { e.preventDefault(); this.postAmazonSubaffiliate(); }}
                                                                    isLoading={this.state.savingAmazonSub}
                                                                >
                                                                    Create Subaffiliate
                                                                </ActionButton>
                                                                :
                                                                <ActionButton className="btn btn-primary"
                                                                    isDisabled={false}
                                                                    onClick={(e) => { e.preventDefault(); this.postAmazonSubaffiliate(); }}
                                                                    isLoading={this.state.savingAmazonSub}
                                                                >
                                                                    Create Subaffiliate
                                                                </ActionButton>
                                                        }

                                                    </div>

                                                </FormGroup>
                                            </div>                                            :
                                           <></>
                                    }
                                    
                                    <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className="row mt-0 mx-0 pt-3 pb-5">
                                            <div className="table-responsive product-table" style={{ overflow: "auto" }}>
                                                <table className="table" style={{ backgroundColor: '#e9ecef' }}>
                                                    <thead>
                                                        <tr>
                                                            <th><strong>Affiliate</strong></th>
                                                            <th style={{ textAlign: "center" }}><strong>Subaffiliate ID</strong></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                            {this.displayTableRows()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                        {this.renderSubmit()}
                                    {
                                        this.displayResetPasswordButton()
                                    }
                                    {
                                        this.displayImpersonateButton()
                                    }
                                        <Popover
                                            placement="right"
                                            target="submitProfileChanges"
                                            isOpen={this.state.showSavedProfile}
                                            toggle={() => this.setState({ savingProfile: false })}
                                        >
                                            <PopoverBody>Profile changes saved</PopoverBody>
                                        </Popover>
                                    </div>
                                </Form>
                            </div>

                    </div>
                </section>
            </>
        )
    }
}

export default Users;